import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { Box } from '@rebass/grid';

import Callout from 'components/callout';
import Layout from 'components/layout';
import HeroSection from 'components/hero';
import Header from 'components/header';
import PageMeta from 'components/page-meta';
import { convertLead } from '../helpers/leadId';
import { ContentBox } from 'styles/boxes.css';
import { FocusH1, H2 } from 'styles/headers.css';
import { P } from 'styles/text.css';

// TODO: Abstract with Referrer page
const CongratulationsPage = ({ data }) => {
  const pageTitle = 'Congratulations';
  const pageDescription = 'Get ready.';
  const boxingImage = data.boxingImage.childImageSharp.fluid;
  const apiUrl = data.site.siteMetadata.apiUrl;
  convertLead(apiUrl);
  return (
    <Layout>
      <PageMeta title={pageTitle} description={pageDescription} />
      <HeroSection>
        <Header />
        <Box width={0.8} m="auto">
          <FocusH1>Get in the Ring</FocusH1>
        </Box>
      </HeroSection>
      <Callout text="Congratulations!" />
      <ContentBox width={0.8} m="auto" p={[3, 4, 5, 5]}>
        <P>
          You are booked for a Gloveworx session. Check your email for
          confirmation. Contact{' '}
          <a
            href="mailto:info@gloveworx.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            info@gloveworx.com
          </a>{' '}
          if you have questions.
        </P>
        <H2>Get ready to become unstoppable.</H2>
      </ContentBox>
      <Box width={0.8} m="auto">
        <Img fluid={boxingImage} />
      </Box>
      <Callout text="" />
    </Layout>
  );
};

CongratulationsPage.propTypes = {
  data: PropTypes.object.isRequired,
};

class CongratulationsPageWithQuery extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                apiUrl
              }
            }
            boxingImage: file(name: { eq: "leyon-box.original" }) {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
        render={data => <CongratulationsPage data={data} />}
      />
    );
  }
}

CongratulationsPage.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        apiUrl: PropTypes.string,
      }),
    }),
    boxingImage: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    gwxapi: PropTypes.shape({
      advocate: PropTypes.object,
    }),
  }),
};

export default CongratulationsPageWithQuery;
